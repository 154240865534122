import { graphql } from "gatsby"
import React, { useRef, useState, useEffect } from "react"
import "react-input-range/lib/css/index.css"
import useOutsideClick from "../../hooks/useOutsideClick"
import { isLoggedIn } from "../../services/auth"

import Layout from "../Page"
import FilterResult from "../../components/ContentBuilder/FilterResult"
import MainDesignFilter from "../Includes/MainDesignFilter"
import SidebarCategory from "../Includes/SidebarCategory"

import AllList from "../../components/Category/All/list"
import Cookies from "universal-cookie"
import RegisterModal from "../../components/ContentBuilder/Modal/RegisterModal"

const ExploreAllProducts = ({ data: { silverStripeDataObject } }) => {
  const [showModal, setShowModal] = useState(true)
  useEffect(() => {
    const cookies = new Cookies()
    var modal_time = 1 * 60 * 60 * 1000
    var modalShowed = cookies.get("modalShow") ? true : false

    if (modalShowed === false) {
      cookies.set("modalShow", true, {
        path: "/explore/",
        expires: new Date(Date.now() + modal_time),
      })
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [])
  const [show, setShow] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setShow(false)
  })
  const { metaTags, customCSS } = silverStripeDataObject.Page
  var introText = 'Choose a lifestyle not a floorplan. Explore our range from layouts and facades to taps and tiles. Love the arch mirror or herringbone tiles? Give it like to add it to your board. Let’s create your perfect home.'
  
  const pageData = {
        pageClass: 'HomeDesign', 
        bodyClass: '', 
        pageTitle: '',
        metaTags: metaTags,
        customCSS: customCSS,
  }
  
    var isSearching = false
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    var keyword = params.get("keyword")
    if(keyword){
        isSearching = true
    }
  return (
    <Layout pageData={pageData}>
      <div className="container">
        <MainDesignFilter intro={introText}/>
        <div className="wrap-section no-margin">
          <div className="explore-function-content">
            <FilterResult />
          </div>
        </div>

        <div className="wrap-section row py-5">
            <div className="col-lg-3">
              <SidebarCategory />
            </div>
            <div className="col-lg-9">
              <AllList/>
            </div>
        </div>
      </div>
      
      {!isLoggedIn && showModal && (
        <div className="c-browser-home-design">
          <div className="browser-home-design-form text-center">
            <button
              type="button"
              className="close"
              onClick={() => setShowModal(false)}
            >
              &times;
            </button>
            <div className="inner">
              <div className="mb-3">
                <img
                  src="/img/account/birdy-logo.svg"
                  alt="Birdy"
                  width="110"
                  height="86"
                />
              </div>
              <p className="h4 mb-3">
                We can ensure the homes your are looking at are the perfect fit
                for your block.
              </p>

              <p className="h4 mb-3">
                Create a free account to add your block of land.
              </p>

              <p className="text-gray">
                Your address is subject to our{" "}
                <a href="#" className="text-gray">
                  Privacy Policy
                </a>
                , and it will only be used <br />
                to show homes that may fit your block.
              </p>

              <div className="text-center">
                <button className="btn btn-black btn-lg btn-w210" onClick={() => {
                    setShowModal(false)
                    }}>
                  Create Account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoggedIn()&& 
        <RegisterModal show={true} />
      }
    </Layout>
  )
}

export default ExploreAllProducts
export const pageQuery = graphql`
  query($link: String!) {
    silverStripeDataObject(link: { eq: $link }) {
      SilverStripeSiteTree {
        title
      }
      Page {
        metaTags
        customCSS
      }
    }
  }
`
